import {
  AnswerRateSection,
  HeaderTextContainer,
  PrimaryToggleHeader,
  ScheduleBody,
  ScheduleContainer,
  ScheduleHeader,
  ScheduleText,
  Separator,
  VerticalSeparator,
} from "./styles";
import { useEffect, useState } from "react";

import LoadingAnimation from "../../../../common/LoadingAnimation";
import { SchedulerLeftSide } from "./SchedulerLeftSide";
import { SchedulerRightSide } from "./SchedulerRightSide";
//@ts-ignore
import scheduleImage from "../../../../../assets/images/schedule.png";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

const Scheduler = () => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [isMailMinuteRandom, setIsMailMinuteRandom] = useState(false);

  const { getAccount } = useLightsApiPrivate();
  useEffect(() => {
    if (loading) {
      getAccount().then((data) => {
        if (data.schedule) setSchedule(data.schedule);
        if (data.isMailMinuteRandom)
          setIsMailMinuteRandom(data.isMailMinuteRandom);
        setLoading(false);
      });
    }
  }, [loading, getAccount]);

  if (loading) return <LoadingAnimation />;

  return (
    <ScheduleContainer>
      <ScheduleHeader>
        <img src={scheduleImage} alt="schedule" height="150px" width="233px" />
        <HeaderTextContainer>
          <PrimaryToggleHeader>Plane die Zeiten.</PrimaryToggleHeader>
          <ScheduleText>
            Du kannst einstellen, an welchen Tagen Du Pulse-Checks versenden
            möchtest, wie viele Fragen Du pro Tag freischalten möchtest und zu
            welcher Uhrzeit Du eine Erinnerung per E-Mail an Deine
            Mitarbeitenden versenden möchtest. Die Beantwortungsfrist ist
            zeitlich unbegrenzt.
          </ScheduleText>
        </HeaderTextContainer>
      </ScheduleHeader>
      <Separator />
      <ScheduleBody>
        <SchedulerLeftSide schedules={schedule} />
        <VerticalSeparator />
        <SchedulerRightSide
          isMailMinuteRandom={isMailMinuteRandom}
          setIsMailMinuteRandom={setIsMailMinuteRandom}
        />
      </ScheduleBody>
      <AnswerRateSection>
        {/* <MediumSchedulerText>
          Mit diesen Einstellungen werden bei der derzeitigen Antwortrate 6720
          Antworten pro Monat erwartet.
        </MediumSchedulerText> */}
      </AnswerRateSection>
    </ScheduleContainer>
  );
};

export default Scheduler;
