export const pronounOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "he_him", label: "Er/Ihn" },
  { id: "she_her", label: "Sie/Ihr" },
  { id: "they_them", label: "Sie/Ihnen" },
  { id: "xe_xem", label: "Xe/Xem" },
  { id: "ze_hir", label: "Ze/Hir" },
  { id: "other_pronouns", label: "Andere" },
  { id: "he_they", label: "Er/Sie" },
  { id: "she_they", label: "Sie/Sie" },
  { id: "he_her_they", label: "Er/Sie/Ihnen" },
  { id: "they_he", label: "Sie/Er" },
  { id: "they_she", label: "Sie/Sie" },
  { id: "other_combinations", label: "Andere Kombinationen" },
];

export const sexualityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "asexual", label: "Asexuell" },
  { id: "bisexual", label: "Bisexuell" },
  { id: "heterosexual", label: "Heterosexuell" },
  { id: "homosexual", label: "Homosexuell" },
  { id: "pansexual", label: "Pansexuell" },
  {
    id: "other_sexual_orientation",
    label: "Ich verwende einen anderen Begriff",
  },
];

export const religionOptions = [
  { id: "not_applicable", label: "Keine Angabe", desc: "Keine Angabe" },

  { id: "no_religion", label: "Keine Religion oder Weltanschauung" },
  {
    id: "alevi_religious_community",
    label: "Alevitische Religionsgemeinschaft",
  },
  { id: "buddhism", label: "Buddhismus" },
  { id: "christian_orthodox_church", label: "Christlich-orthodoxe Kirche" },
  { id: "protestant_church", label: "Evangelische Kirche" },
  { id: "hinduism", label: "Hinduismus" },
  { id: "islam", label: "Islamische Religionsgemeinschaft" },
  { id: "judaism", label: "Judentum" },
  { id: "roman_catholic_church", label: "Römisch-katholische Kirche" },
  {
    id: "worldly_secular_worldview_community",
    label: "Weltliche/Säkulare Weltanschauungsgemeinschaft",
  },
  { id: "other_religious_belief", label: "Andere religiöse Überzeugung" },
];

export const ethnicityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "east_asian", label: "Ost-Asiatisch" },
  { id: "hispanic", label: "Lateinamerikanisch" },
  { id: "black", label: "Schwarz" },
  { id: "white", label: "Weiß" },
  { id: "other_ethnicity", label: "Andere Selbstbezeichnung" },
  { id: "person_of_color", label: "Person of Color" },
  { id: "jewish", label: "Jüdisch" },
  { id: "muslim", label: "Muslimisch" },
  { id: "sintizzes_roma", label: "Sinti*zze und Rom*nja" },
  { id: "arabic", label: "Arabisch" },
  { id: "turkish", label: "Türkisch" },
  { id: "slawic", label: "Slawisch" },
];

export const disabilityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "no_disability", label: "Ich habe keine Behinderung" },
  { id: "intellectual_disability", label: "Geistige Behinderung" },
  { id: "hearing_impairment", label: "Hörschädigung" },
  { id: "physical_disability", label: "Körperliche Behinderung" },
  { id: "learning_disability", label: "Lernbehinderung" },
  { id: "severe_disability", label: "Schwerbehinderung" },
  { id: "psychological_impairment", label: "Seelische Behinderung" },
  { id: "visual_impairment", label: "Sehbehinderung" },
  { id: "speech_impairment", label: "Sprachbehinderung" },
  { id: "invisible_disability", label: "Unsichtbare Behinderung" },
  { id: "behavioural_disorder", label: "Verhaltensstörung" },
  { id: "developmental_disorder", label: "Entwicklungsstörung" },
  { id: "other_disability", label: "Andere Behinderung" },
];

export const genderOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "genderless", label: "Kein Geschlecht" },
  { id: "male", label: "Männlich" },
  { id: "diverse", label: "Nicht-Binär" },
  { id: "female", label: "Weiblich" },
  { id: "other_gender", label: "Andere Geschlechtsidentität" },
];

export const nationalityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "argentinian", label: "Argentininasch" },
  { id: "australian", label: "Australisch" },
  { id: "belgian", label: "Belgisch" },
  { id: "chilean", label: "Chilenisch" },
  { id: "czech", label: "Tschechisch" },
  { id: "danish", label: "Dänisch" },
  { id: "egyptian", label: "Ägyptisch" },
  { id: "finnish", label: "Finnisch" },
  { id: "german", label: "Deutsch" },
  { id: "greek", label: "Griechisch" },
  { id: "hungarian", label: "Ungarisch" },
  { id: "irish", label: "Irisch" },
  { id: "korean", label: "Koreanisch" },
  { id: "mexican", label: "Mexikanisch" },
  { id: "new_zealand", label: "Neuseeländisch" },
  { id: "northern_irish", label: "Nordirisch" },
  { id: "norwegian", label: "Norwegisch" },
  { id: "peruvian", label: "Peruanisch" },
  { id: "polish", label: "Polnisch" },
  { id: "portuguese", label: "Portugiesisch" },
  { id: "scottish", label: "Schottisch" },
  { id: "south_african", label: "Südafrikanisch" },
  { id: "swedish", label: "Schwedisch" },
  { id: "swiss", label: "Schweizerisch" },
  { id: "turkish", label: "Türkisch" },
  { id: "us_american", label: "US-amerikanisch" },
  { id: "welsh", label: "Walisisch" },
  { id: "other_nationality", label: "Andere Nationalität" },
];

export const socialBackgroundOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  {
    id: "niedrig",
    label:
      "Niedrig - nie berufstätig gewesen & ungelernte Arbeit, z. B. Kassierer*innen",
    desc: "Niedrig",
  },
  {
    id: "mittel",
    label:
      "Mittel - gelernte Arbeit z. B. Krankenpflege; selbständig & freiberuflich mit niedrigem Einkommen",
    desc: "Mittel",
  },
  {
    id: "gehoben",
    label:
      "Gehoben - angestellt & selbständig mit/ohne akademischen Abschluss und mittlerem Einkommen",
    desc: "Gehoben",
  },
  {
    id: "hoch",
    label:
      "Hoch - angestellt & selbständig mit/ohne akademischen Abschluss und hohem Einkommen",
    desc: "Hoch",
  },
];

export const ageGroupOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "18-24", label: "18-24" },
  { id: "25-34", label: "25-34" },
  { id: "35-44", label: "35-44" },
  { id: "45-54", label: "45-54" },
  { id: "55-64", label: "55-64" },
  { id: "65-74", label: "65-74" },
  { id: "u74", label: "Ü74" },
];

export const levelOptions = [
  { id: "Junior Level", label: "Junior Level" },
  { id: "Head of", label: "Head of" },
  { id: "C-Level", label: "C-Level" },
  { id: "Senior Manager", label: "Senior Manager" },
  { id: "Team Lead", label: "Team Lead" },
  { id: "Student*in", label: "Student*in" },
];

export const statusOptions = [
  { id: "created", label: "Erstellt" },
  { id: "invited", label: "Eingeladen" },
  { id: "active", label: "Aktiv" },
  { id: "deactivated", label: "Deaktiviert" },
];

export const getOptionById = (option) => {
  return allOptions.find((o) => o.id === option);
};

export const allOptions = [
  { id: "average", label: "Durchschnitt", desc: "Durchschnitt" },
  { id: "variance", label: "Varianz", desc: "Varianz" },
  ...statusOptions,
  ...pronounOptions,
  ...sexualityOptions,
  ...religionOptions,
  ...levelOptions,
  ...ethnicityOptions,
  ...disabilityOptions,
  ...ageGroupOptions,
  ...genderOptions,
  ...nationalityOptions,
  ...socialBackgroundOptions,
];
